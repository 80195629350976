import { Container, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { BasePageContext } from '../base_page/base_page'
import { Helmet } from 'react-helmet';

function HomePage() {

    const [currentUser, pageLoading] = useContext(BasePageContext);

    return (
        <Container>
            <Helmet>
                <title>My Account - Kabejo</title>
            </Helmet>
            <Typography>{pageLoading === true
                ? "Loading..."
                : currentUser != null
                    ? `Logged in as ${currentUser.firstName != null ? currentUser.firstName : "No name"} ${currentUser.lastName != null ? currentUser.lastName : ""}`
                    : "No user Logged in"}
            </Typography>
        </Container>
    )
}

export default HomePage