// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyCcXk_uUqwCov8yEaxg1YrqwdWNiLawwfw",
  authDomain: "kabejo-india.firebaseapp.com",
  databaseURL: "https://kabejo-india-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "kabejo-india",
  storageBucket: "kabejo-india.appspot.com",
  messagingSenderId: "433904949635",
  appId: "1:433904949635:web:0290aca44bd1d7fc1e7eb6",
  measurementId: "G-GCN6N1KY01"
};

export const firebaseApp = initializeApp(firebaseConfig);
