import {
    Box, CardMedia, Paper, Stack, Typography, Card, TextField, FormControl,
    FormHelperText, useFormControl, OutlinedInput,
    InputAdornment,
    Alert
} from '@mui/material'
import React, { useRef, useState } from 'react'
import Logo from "../../images/kabejo_logo.png";
import IndianFlag from "../../images/india_flag.webp";
import FilledButton from '../../components/buttons/filledbutton';
import LoginPageBannerContainer from '../../container/login_page/login_page_banner_container';
import { useNavigate } from 'react-router-dom';
import { getAuth, signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth"
import { firebaseApp } from '../../firebase/firebase_init';
import { Helmet } from 'react-helmet';

function MyFormHelperText() {
    const { focused } = useFormControl() || {};

    const helperText = React.useMemo(() => {
        if (focused) {
            return 'This field is being focused';
        }

        return 'Helper text';
    }, [focused]);

    return <FormHelperText>{helperText}</FormHelperText>;
}



function LoginPage() {


    const navigate = useNavigate();
    var phoneNumberRef = useRef();
    const auth = getAuth(firebaseApp);


    async function sendCode() {
        var phoneNumber = phoneNumberRef.current.value;
        if (phoneNumber.length == 10) {

            console.log(`PHONE NUMBER:  ${phoneNumber}`);

            try {

                if (!window.recaptchaVerifier) {
                    window.recaptchaVerifier = new RecaptchaVerifier(auth, "recaptcha-container", {
                        size: "invisible",
                        callback: (response) => {
                            console.log(response);
                        },
                        "expired-callback": () => { },
                    });
                }
                const appVerifier = window.recaptchaVerifier
                const confirmationResult = await signInWithPhoneNumber(auth, `+91${phoneNumber}`, appVerifier);
                const vid = confirmationResult.verificationId;
                navigate(`/login/verify?phone=${phoneNumber}&vid=${vid}`, { replace: true })
            } catch (error) {
                console.log("Error sending OTP:", error);
            }

        } else {
            console.log(`ERROR: LENGTH IS ${phoneNumber.length}`);
        }
    }

    return (
        <Box>
            <Helmet>
                <title>Login - Kabejo Account</title>
            </Helmet>
            <Box sx={{ display: "flex", flexDirection: "row", flexGrow: 1 }}>
                <LoginPageBannerContainer />
                <Box
                    sx={{
                        display: "flex",
                        flexGrow: 1,
                        height: "100vh",
                        flexDirection: "column",
                        paddingLeft: { xs: 8, sm: 20, md: 8 },
                        paddingRight: { xs: 8, sm: 20, md: 8 },
                        justifyContent: "center",
                        bgcolor: { xs: "transparent", sm: "transparent", md: "background.login_background" },
                    }}>
                    <Stack flexDirection="column" sx={{
                        display: "flex",
                        alignItems: "center",
                    }}>
                        <Box
                            component="img"
                            src={Logo}
                            height={60}
                            width={60}
                            alt="Kabejo Logo"
                            sx={{
                                marginBottom: 6,
                                display: {
                                    xs: "flex",
                                    sm: "flex",
                                    md: "none"
                                }
                            }} />
                        <Typography sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: 24,
                            fontFamily: "DM Serif Text"
                        }}>Login to your account</Typography>
                    </Stack>
                    <form noValidate autoComplete="off">
                        <FormControl sx={{ display: "flex", marginTop: 6, }}>
                            <OutlinedInput
                                inputRef={phoneNumberRef}
                                fullWidth
                                placeholder='Enter your phone number'
                                color="text.primary"
                                type='tel'
                                inputProps={{ maxLength: 10 }}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <img src={IndianFlag}
                                            width={24} />
                                    </InputAdornment>
                                }
                                sx={{ backgroundColor: "background.login_input" }}
                                onKeyDown={(e) => e.key === "Enter" ? sendCode() : null} />
                            <MyFormHelperText />
                        </FormControl>
                    </form>
                    <FilledButton text="Continue" sx={{ marginTop: 4, }}
                        onClick={() => sendCode()} />
                    <div id='recaptcha-container'></div>
                </Box>
            </Box >
        </Box>
    )
}

export default LoginPage