import { getAuth, onAuthStateChanged } from 'firebase/auth';
import React, { createContext, useEffect, useState } from 'react'
import { firebaseApp } from '../../firebase/firebase_init';
import { useNavigate } from 'react-router-dom';
import { Container } from '@mui/material';
import { doc, getDoc } from 'firebase/firestore';
import firebaseDatabase from '../../firebase/firebase_db'

export const BasePageContext = createContext();

function BasePage({ page }) {

    const auth = getAuth(firebaseApp);
    const navigate = useNavigate();

    const [authUserId, setAuthUserId] = useState("");
    const [currentUser, setCurrentUser] = useState();
    const [pageLoading, setPageLoading] = useState(true);

    useEffect(() => {
        checkUserLogin();
    }, []);

    async function checkUserLogin() {

        onAuthStateChanged(auth, async (user) => {
            if (user) {
                console.log(`USER DATA: ${user}`)
                setAuthUserId(user.uid);
                loadCurrentUserInfo(user.uid);
            } else {
                setPageLoading(false);
                navigate('/login', { replace: true });
            }
        });

    }

    async function loadCurrentUserInfo(uid) {
        const docRef = doc(firebaseDatabase, "users", uid);
        const docSnap = await getDoc(docRef);
        console.log(`CURRENT USER DATA: ${docSnap.data().firstName}`);

        if (docSnap.exists) {

            const fetchedData = {
                id: docSnap.id,
                firstName: docSnap.data().firstName,
                lastName: docSnap.data().lastName,
            };
            setPageLoading(false);
            setCurrentUser(fetchedData);
        } else {
            setPageLoading(false);
            setCurrentUser({
                id: "",
                firstName: "",
                lastName: "",
            });
        }
    }

    return (
        <BasePageContext.Provider value={[currentUser, pageLoading]}>
            <Container>
                {page}
            </Container>
        </BasePageContext.Provider>
    );
}

export default BasePage