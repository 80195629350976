import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AppUI from "./config/theme";
import LoginPage from "./pages/login_page/login_page";
import HomePage from "./pages/home_page/home_page";
import DeleteAccountPage from "./pages/delete_account_page/delete_account_page";
import VerifyPhoneNumberDuringLoginPage from "./pages/login_page/verify_phone_number_page";
import BasePage from "./pages/base_page/base_page";

function App() {
  return (
    <AppUI>
      <Router>
        <Routes>
          <Route path="/" element={<BasePage page={<HomePage />} />} />
          <Route path='/login'>
            <Route index element={<LoginPage />} />
            <Route path="verify" element={<VerifyPhoneNumberDuringLoginPage />} />
          </Route>
          <Route path="/delete-account" element={<BasePage page={<DeleteAccountPage />} />} />
        </Routes>
      </Router>
    </AppUI>
  );
}

export default App;
