import {
    Box, CardMedia, Paper, Stack, Typography, Card, TextField, FormControl,
    FormHelperText, useFormControl, OutlinedInput,
    InputAdornment,
    Chip
} from '@mui/material'
import 'material-symbols';
import React, { useEffect, useRef, useState } from 'react'
import Logo from "../../images/kabejo_logo.png";
import IndianFlag from "../../images/india_flag.webp";
import FilledButton from '../../components/buttons/filledbutton';
import TextButton from '../../components/buttons/textbutton';
import LoginPageBannerContainer from '../../container/login_page/login_page_banner_container';
import { useNavigate, useLocation } from 'react-router-dom';
import { getAuth, signInWithCredential, PhoneAuthProvider } from 'firebase/auth';
import { firebaseApp } from '../../firebase/firebase_init';

function MyFormHelperText() {
    const { focused } = useFormControl() || {};

    const helperText = React.useMemo(() => {
        if (focused) {
            return 'This field is being focused';
        }

        return 'Helper text';
    }, [focused]);

    return <FormHelperText>{helperText}</FormHelperText>;
}


function VerifyPhoneNumberDuringLoginPage() {

    var otpRef = useRef();
    const navigate = useNavigate();
    const location = useLocation();

    const auth = getAuth(firebaseApp);

    const params = new URLSearchParams(location.search);
    const fetchedPhoneNumber = params.get("phone");
    const fetchedvId = params.get("vid");

    useEffect(() => {
        if (fetchedPhoneNumber == null ||
            fetchedPhoneNumber.length != 10 ||
            fetchedPhoneNumber == 0 ||
            fetchedvId == null) {
            navigate('/login');
        }
    }, [fetchedPhoneNumber]);


    async function verifyCode() {

        var code = otpRef.current.value;

        if (!auth) return;

        const credential = PhoneAuthProvider.credential(fetchedvId, code);

        await signInWithCredential(auth, credential)
            .then((result) => {
                console.log("successfull app")
                navigate('/');
            })
            .catch((error) => {
                console.error("Error verifying OTP:", error);
            });

    }

    return (
        <Box sx={{ display: "flex", flexDirection: "row", flexGrow: 1 }}>
            <LoginPageBannerContainer />
            <Box sx={{
                display: "flex",
                flexGrow: 1,
                height: "100vh",
                flexDirection: "column",
                paddingLeft: { xs: 8, sm: 20, md: 8 },
                paddingRight: { xs: 8, sm: 20, md: 8 },
                justifyContent: "center",
                bgcolor: { xs: "transparent", sm: "transparent", md: "background.login_background" },
            }}>
                <Stack flexDirection="column" sx={{
                    display: "flex",
                    alignItems: "center",
                }}>
                    <Box
                        component="img"
                        src={Logo}
                        height={60}
                        width={60}
                        alt="Kabejo Logo"
                        sx={{
                            marginBottom: 6,
                            display: {
                                xs: "flex",
                                sm: "flex",
                                md: "none"
                            }
                        }} />
                    <Typography sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: 24,
                        fontFamily: "DM Serif Text"
                    }}>Check your phone for code</Typography>
                    <Typography sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: 14,
                        textAlign: "center",
                        marginTop: 1,
                    }}>We sent you a verification code to the below phone number. If your phone number is wrong, Change by clicking below</Typography>
                    <Chip
                        label={fetchedPhoneNumber}
                        onClick={() => navigate('/login')}
                        icon={<span className='material-symbols-outlined' style={{ fontSize: 18 }}>edit</span>}
                        sx={{ marginTop: 2, }}
                    />
                </Stack>
                <form noValidate autoComplete="off">
                    <FormControl sx={{ display: "flex", marginTop: 6, }}>
                        <OutlinedInput
                            inputRef={otpRef}
                            fullWidth
                            placeholder='Enter code'
                            color="text.primary"
                            type='tel'
                            inputProps={{ maxLength: 6 }}
                            sx={{ backgroundColor: "background.login_input" }}
                            onKeyDown={(e) => e.key === "Enter" ? verifyCode() : null} />
                        <MyFormHelperText />
                    </FormControl>
                </form>
                <Stack flexDirection="row" sx={{
                    alignItems: "center",
                    justifyContent: "end"
                }}>
                    <Typography>Did not recieve code?</Typography>
                    <TextButton text="Resend" />
                </Stack>
                <FilledButton text="Login" sx={{ marginTop: 2, }} onClick={() => verifyCode()} />
            </Box>
        </Box >
    )
}

export default VerifyPhoneNumberDuringLoginPage