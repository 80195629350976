import {
    Container, Link, List, ListItem, ListItemIcon, ListItemText, Stack, Typography,
    FormControl, FormControlLabel, FormLabel, RadioGroup, Radio, Dialog,
    Checkbox,
    CircularProgress,
    Box,
    TextField,
    DialogContent,
    DialogContentText,
    Avatar,
} from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { BasePageContext } from '../base_page/base_page';
import FilledButton from '../../components/buttons/filledbutton';
import { getAuth, deleteUser } from "firebase/auth";
import { firebaseApp } from '../../firebase/firebase_init';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function DeleteAccountPage() {

    const navigate = useNavigate();


    const [currentUser, pageLoading] = useContext(BasePageContext);

    const radioButton1 = useRef();
    const radioButton2 = useRef();
    const radioButton3 = useRef();
    const radioButton4 = useRef();
    const radioButton5 = useRef();
    const radioButton6 = useRef();

    const checkBoxRef = useRef();

    const otherReasonInputRef = useRef();

    const [buttonEnable, setButtonEnable] = useState(false);
    const [inputEnable, setInputEnable] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogShowProgress, setDialogShowProgress] = useState(false);
    const [dialogText, setDialogText] = useState("");

    const checkEnableInput = () => {
        if (radioButton6.current.checked !== true) {
            setInputEnable(true);
        } else {
            setInputEnable(false);
        }
    }

    const checkFeedback = () => {
        checkEnableInput();
        if (radioButton1.current.checked !== true &&
            radioButton2.current.checked !== true &&
            radioButton3.current.checked !== true &&
            radioButton4.current.checked !== true &&
            radioButton5.current.checked !== true &&
            otherReasonInputRef.current.value.length < 6
        ) {
            setButtonEnable(false);
        } else {
            if (checkBoxRef.current.checked !== true) {
                setButtonEnable(false);
            } else {
                setButtonEnable(true);
            }
        }
    }

    async function deleteAccount() {
        const auth = getAuth(firebaseApp);
        const user = auth.currentUser;
        setDialogOpen(true);
        setDialogShowProgress(true);
        setDialogText("Deleting account...");
        await deleteUser(user).then(() => {
            setDialogShowProgress(false);
            setDialogOpen(false);
            setDialogOpen(true);
            setDialogText("Account deleted");
            setInterval(navigate('/login', { replace: true }), 2000);
        }).catch((error) => {
            console.log(error);
            setDialogShowProgress(false);
            setDialogOpen(false);
            setDialogOpen(true);
            setDialogText("Error deleting your account");
            setInterval(setDialogOpen(false), 2000);
        });
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const DialogContainer = () => {
        return (
            <Dialog open={dialogOpen}>
                <DialogContent sx={{
                    paddingLeft: { md: 6 },
                    paddingRight: { md: 6 },
                    paddingTop: { md: 4 },
                    paddingBottom: { md: 4 },
                }}>
                    <DialogContentText>
                        <Stack
                            flexDirection="row"
                            direction="row"
                            sx={{
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                            {dialogShowProgress === true ? <CircularProgress /> : ""}
                            {dialogText === "Account deleted" ? <Avatar sx={{ bgcolor: "green" }}>
                                <span className='material-symbols-outlined'>check</span>
                            </Avatar> : <Avatar sx={{ bgcolor: "red" }}>
                                <span className='material-symbols-outlined'>error</span>
                            </Avatar>}
                            <Typography color='text.primary' sx={{ marginLeft: 3, fontSize: 16, }}>{dialogText}</Typography>
                        </Stack>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }

    if (pageLoading) {
        return (
            <Container>
                <Box sx={{
                    display: "flex",
                    flexGrow: 1,
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100vh"
                }}>
                    <CircularProgress />
                </Box>
            </Container>
        );
    }

    return (
        <Container>
            <Helmet>
                <title>Delete My Account - Kabejo</title>
            </Helmet>
            <Typography sx={{ fontSize: 26, fontFamily: "DM Serif Text", marginTop: 4 }}>Delete My Account</Typography>
            <Typography sx={{ fontSize: 16, fontWeight: 400, marginTop: 3 }}>Dear {currentUser.firstName != null ? currentUser.firstName : "No name"} {currentUser.lastName != null ? currentUser.lastName : ""}, Since you've been a valuable person to us in Kabejo. We are feeling so sad that you took this decision. If you are facing any issue please contact <Link>Customer support</Link>, So that one of our support person will assist you regarding your problem and may help solving it</Typography>
            <Typography sx={{ fontSize: 16, fontWeight: 500, marginTop: 6 }}>
                Deleting your Kabejo account leads to these permenant actions
            </Typography>
            <List>
                <ListItem>
                    <ListItemIcon>
                        <span className='material-symbols-outlined' style={{ fontSize: 24 }}>check</span>
                    </ListItemIcon>
                    <ListItemText >
                        Your ads get deleted
                    </ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <span className='material-symbols-outlined' style={{ fontSize: 24 }}>check</span>
                    </ListItemIcon>
                    <ListItemText>
                        Ads you liked will be removed
                    </ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <span className='material-symbols-outlined' style={{ fontSize: 24 }}>check</span>
                    </ListItemIcon>
                    <ListItemText>
                        You will lose your Subscriptions (No refund will be issued. Even though you having a subcription till date)
                    </ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <span className='material-symbols-outlined' style={{ fontSize: 24 }}>check</span>
                    </ListItemIcon>
                    <ListItemText>
                        Your profile info will be removed from Internet and others will no longer find you and your ads when they search in Google, Bing, etc.
                    </ListItemText>
                </ListItem>
            </List>
            <Typography sx={{ fontSize: 16, fontWeight: 500, marginTop: 6 }}>
                If you still wish to proceed deleting your account, Please provide a reason before you go
            </Typography>
            <FormControl sx={{ marginTop: 2, }}>
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group">
                    <FormControlLabel
                        inputRef={radioButton1}
                        value="needs_development"
                        control={<Radio />}
                        label="Kabejo still needs to improve"
                        onChange={checkFeedback} />
                    <FormControlLabel
                        inputRef={radioButton2}
                        value="subscription_fee"
                        control={<Radio />}
                        label="Subscription costs are too high"
                        onChange={checkFeedback} />
                    <FormControlLabel
                        inputRef={radioButton3}
                        value="price_better"
                        control={<Radio />}
                        label="I'm getting paid for some features that are free on other apps"
                        onChange={checkFeedback} />
                    <FormControlLabel
                        inputRef={radioButton4}
                        value="non_functioning"
                        control={<Radio />}
                        label="Kabejo (App or Website) does not load sometimes or full of Bugs"
                        onChange={checkFeedback} />
                    <FormControlLabel
                        inputRef={radioButton5}
                        value="better_apps"
                        control={<Radio />}
                        label="Alternative apps such as OLX, Cashify, Quikr are much better"
                        onChange={checkFeedback} />
                    <FormControlLabel
                        inputRef={radioButton6}
                        value="other"
                        control={<Radio />}
                        label="My reason is not listen here"
                        onChange={checkFeedback} />
                </RadioGroup>
                <TextField
                    inputRef={otherReasonInputRef}
                    label="Reason"
                    placeholder='Please explain in detail (atleast one word)'
                    multiline
                    maxRows={4}
                    onChange={checkFeedback}
                    disabled={inputEnable}
                    sx={{ marginTop: 2, }}
                />
                <FormControlLabel
                    inputRef={checkBoxRef}
                    value="end"
                    control={<Checkbox />}
                    label="I here by confirm that I wish to delete my account knowing the permenent actions that happen to my account after deletion"
                    labelPlacement="end"
                    sx={{
                        marginTop: 6,
                        alignItems: "start"
                    }}
                    onChange={checkFeedback}
                />
            </FormControl>

            <FilledButton
                icon="delete"
                iconPosition="left"
                text="Delete permenantly"
                sx={{
                    bgcolor: "red",
                    marginTop: 2,
                    marginBottom: 8,
                }}
                disabled={!buttonEnable}
                onClick={() => deleteAccount()} />
            <DialogContainer />
        </Container >
    )
}

export default DeleteAccountPage