import { Box, Stack, Typography } from '@mui/material'
import Logo from "../../images/kabejo_logo.png";
import BannerLargeImg from "../../images/login_banner_lg.png";
import React from 'react'

const LoginPageBannerContainer = () => {
    return (
        <Box sx={{
            display: { xs: "none", sm: "none", md: "flex" },
            height: "100vh",
            width: "60%",
            bgcolor: "background.login_banner",
            flexDirection: "column",
            justifyContent: "center",
        }}>
            <Stack
                direction="row"
                flexDirection="row"
                sx={{
                    alignItems: "center",
                    marginLeft: 8,
                    marginRight: 8,
                }}>
                <img src={Logo} height={48} width={48} alt="Kabejo Logo" />
                <Typography sx={{ fontWeight: 700, fontSize: 30, }}>Kabejo</Typography>
            </Stack>
            <Typography sx={{
                fontWeight: 400,
                fontSize: 22,
                marginLeft: 9,
                marginTop: 2,
                marginRight: 12,
            }}>Buy & Sell second hand mobile phones, bikes, cars, laptops and much more.</Typography>

            <Box component='img' src={BannerLargeImg} height="350" />

        </Box>
    )
}

export default LoginPageBannerContainer